<template>
  <div>
    <b-button variant="primary" @click="goToCreate()">
      Crear Inversió
    </b-button>
  </div>
</template>

<script>
import {BRow, BCol, BButton, BCard, BBadge, BOverlay} from 'bootstrap-vue'
import vSelect from "vue-select"
import {INVESTMENT_CREATE} from "@/router/web/constants"

export default {
  name: "CreateInvestmentButton",
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BButton,
    BOverlay,

    vSelect,
  },
  methods: {
    goToCreate() {
      this.$router.push(INVESTMENT_CREATE)
    },
  }
}
</script>